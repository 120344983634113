<template lang="pug">
#EmailEditor
    div(v-show='!fromCampaign').py-4
        loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')   
        v-col.py-1(md='12' v-if='isO365 | objectType == "SMS"')
            p.d-flex.align-center.input-has-label
                label {{$t('CAMPAIGN_DETAIL.TEMPLATE')}}
                v-select.no-border(v-if='objectType == "EMAIL"' v-model='emailTemplate' solo :items='emailTemplateList' item-text='template_name' item-value='id' @change='changeTemplate')
                v-select.no-border(v-else solo v-model='smsTemplate' :items='smsTemplateList' item-text='template_name' item-value='id' @change='changeTemplate') 
        v-col(cols='12')
            .d-flex.align-center
                v-icon.mr-1(size='25' v-if='(objectType == "EMAIL"&& isO365)|objectType == "SMS"') mdi-send-outline
                h3.my-0.d-block.ellipsis(v-if='objectType == "EMAIL"&& isO365') {{$t('CAMPAIGN_DETAIL.EMAIL_CONTENT')}}
                h3.my-0.d-block.ellipsis(v-if='objectType == "SMS"') {{$t('CAMPAIGN_DETAIL.SMS_CONTENT')}}
                v-row.justify-md-end.mr-3(no-gutters='no-gutters' v-if='(objectType == "EMAIL"&& isO365)|objectType == "SMS"')
                    v-btn(@click='preview' width='80' height='32' color='green' depressed dark)
                        v-icon.mr-1(size='14') mdi-eye
                        span {{$t('CAMPAIGN_DETAIL.PREVIEW')}}
        v-row(no-gutters v-if='objectType == "EMAIL" && isO365 && showDialogContent')
            v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                p.d-flex.align-center.input-has-label
                    label {{$t('CAMPAIGN_DETAIL.SUBJECT')}}
                    v-text-field(v-model='subject' solo dense)
            v-col(style='padding:1rem 1rem 0 1rem' cols='12')
                p.d-flex.align-center
                    v-file-input.pt0(ref='formEmailFile', :label=`$t('CAMPAIGN_DETAIL.ATTACHMENT')`, placeholder='' :rules="formRules.fileRule" v-model='attachments' dense multiple show-size small-chips counter truncate-length="15" @change='onChangeFile')
            v-col.mt-5(cols='12' style='padding:1rem 1rem 0 1rem')
                vue-editor(v-model='template' :editor-toolbar='customToolbar' @selection-change='onSelectionChange' @text-change='onTextChange')
                .editor-custom-toolbar.text-right
                    v-menu(top :close-on-click='true' :close-on-content-click='false')
                        template(v-slot:activator='{ on, attrs }')
                            v-btn.px-3(v-bind='attrs' v-on='on' text :ripple='false')
                                v-icon.mr-1(size='15') icon-add
                                span Fields
                        v-card.fields
                            v-tabs(v-model='emailTemplateTabs' hide-slider)                            
                                v-tab.px-0(href="#tab-user" :ripple='false')
                                    v-icon(size='19') icon-user-1
                            v-tabs-items(v-model='emailTemplateTabs')                            
                                v-tab-item(value='tab-user' transition='none')
                                    v-list.d-flex.flex-column(dense)
                                        v-btn(text @click="addEmailTemplateFields('contact.first_name')") First Name
                                        v-btn(text @click="addEmailTemplateFields('contact.last_name')") Last Name
                                        v-btn(text @click="addEmailTemplateFields('contact.owner')") Owner
                                        v-btn(text @click="addEmailTemplateFields('contact.level')") Level
                                        v-btn(text @click="addEmailTemplateFields('contact.department')") Department
                                        v-btn(text @click="addEmailTemplateFields('contact.organization')") Organization
                                        v-btn(text @click="addEmailTemplateFields('contact.email')") Email
                                        v-btn(text @click="addEmailTemplateFields('contact.mobile_phone')") Mobile Phone
                                        v-btn(text @click="addEmailTemplateFields('contact.office_phone')") Office Phone
                                        v-btn(text @click="addEmailTemplateFields('contact.birthdate')") Birthday
                                        v-btn(text @click="addEmailTemplateFields('contact.title')") Title
        v-row(no-gutters v-if='objectType == "EMAIL" && !isO365 && !showDialogContent')
            v-col.py-1(cols='12')
                span.d-flex.align-center.justify-center(v-if="!invalidO365Token") {{$t('CAMPAIGN_DETAIL.NO_O365')}}
                span.d-flex.align-center.justify-center(v-if="invalidO365Token") {{$t('CAMPAIGN_DETAIL.INVALID_O365_TOKEN')}}
                p
                v-row.d-flex.align-center.justify-center(no-gutters='no-gutters')                
                    v-btn(@click='goEmailSync' width='80' height='32' color='green' depressed dark)                
                        span {{$t('CAMPAIGN_DETAIL.GO_SETTING')}}
                    //- label 請先同步O365帳號                                                            
        v-row(no-gutters v-if='objectType == "SMS"')
            v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                p.d-flex.align-center.input-has-label
                    label {{$t('CAMPAIGN_DETAIL.SMS_CONTENT_2')}}
                    v-textarea.textarea-style(v-model='template' solo dense) 
    v-card(v-show='fromCampaign').py-4
      v-card-title.d-flex.justify-center 
        span(v-if='objectType == "EMAIL"').ml-auto.d-flex.justify-center {{$t('CONTACT.EMAIL_PUSH')}}
        span(v-if='objectType == "SMS"').ml-auto.d-flex.justify-center {{$t('CONTACT.SMS_PUSH')}}
        v-btn.ml-auto(
            :ripple="false",
            @click="onCancel()",
            depressed="depressed",
            icon
            )
            v-icon icon-cancel
      v-card-text
        loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')   
        v-col.py-1(md='12' v-if='isO365')
            p.d-flex.align-center.input-has-label
                label {{$t('CAMPAIGN_DETAIL.TEMPLATE')}}
                v-select.no-border(v-if='objectType == "EMAIL"' hide-details v-model='emailTemplate' solo :items='emailTemplateList' item-text='template_name' item-value='id' @change='changeTemplate')
                v-select.no-border(v-else solo v-model='smsTemplate' hide-details :items='smsTemplateList' item-text='template_name' item-value='id' @change='changeTemplate') 
        v-col(cols='12')
            .d-flex.align-center
                v-icon.mr-1(size='25' v-if='(objectType == "EMAIL"&& isO365)|objectType == "SMS"') mdi-send-outline
                h3.my-0.d-block.ellipsis(v-if='objectType == "EMAIL"&& isO365') {{$t('CAMPAIGN_DETAIL.EMAIL_CONTENT')}}
                h3.my-0.d-block.ellipsis(v-if='objectType == "SMS"') {{$t('CAMPAIGN_DETAIL.SMS_CONTENT')}}
                v-row.justify-md-end.mr-3(no-gutters='no-gutters' v-if='(objectType == "EMAIL"&& isO365)|objectType == "SMS"')
                    v-btn(@click='preview' width='80' height='32' color='green' depressed dark)
                        v-icon.mr-1(size='14') mdi-eye
                        span {{$t('CAMPAIGN_DETAIL.PREVIEW')}}
        v-row(no-gutters v-if='objectType == "EMAIL" && isO365 && showDialogContent')
            v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                p.d-flex.align-center.input-has-label
                    label {{$t('CAMPAIGN_DETAIL.SUBJECT')}}
                    v-text-field(v-model='subject' hide-details solo dense)
            v-col(style='padding:1rem 1rem 0 1rem' cols='12')
                p.d-flex.align-center
                    v-file-input.pt0(ref='formEmailFile', :label=`$t('CAMPAIGN_DETAIL.ATTACHMENT')` placeholder='' :rules="formRules.fileRule" v-model='attachments' dense multiple show-size small-chips counter truncate-length="15" @change='onChangeFile')
            v-col.mt-5(cols='12' style='padding:1rem 1rem 0 1rem')
                vue-editor(v-model='template' :editor-toolbar='customToolbar' @selection-change='onSelectionChange' @text-change='onTextChange')
                .editor-custom-toolbar.text-right
                    v-menu(top :close-on-click='true' :close-on-content-click='false')
                        template(v-slot:activator='{ on, attrs }')
                            v-btn.px-3(v-bind='attrs' v-on='on' text :ripple='false')
                                v-icon.mr-1(size='15') icon-add
                                span Fields
                        v-card.fields
                            v-tabs(v-model='emailTemplateTabs' hide-slider)                            
                                v-tab.px-0(href="#tab-user" :ripple='false')
                                    v-icon(size='19') icon-user-1
                            v-tabs-items(v-model='emailTemplateTabs')                            
                                v-tab-item(value='tab-user' transition='none')
                                    v-list.d-flex.flex-column(dense)
                                        v-btn(text @click="addEmailTemplateFields('contact.first_name')") First Name
                                        v-btn(text @click="addEmailTemplateFields('contact.last_name')") Last Name
                                        v-btn(text @click="addEmailTemplateFields('contact.owner')") Owner
                                        v-btn(text @click="addEmailTemplateFields('contact.level')") Level
                                        v-btn(text @click="addEmailTemplateFields('contact.department')") Department
                                        v-btn(text @click="addEmailTemplateFields('contact.organization')") Organization
                                        v-btn(text @click="addEmailTemplateFields('contact.email')") Email
                                        v-btn(text @click="addEmailTemplateFields('contact.mobile_phone')") Mobile Phone
                                        v-btn(text @click="addEmailTemplateFields('contact.office_phone')") Office Phone
                                        v-btn(text @click="addEmailTemplateFields('contact.birthdate')") Birthday
                                        v-btn(text @click="addEmailTemplateFields('contact.title')") Title
        v-row(no-gutters v-if='objectType == "EMAIL" && !isO365 && showDialogContent')
            v-col.py-1(cols='12')
                span.d-flex.align-center.justify-center(v-if="!invalidO365Token") {{$t('CAMPAIGN_DETAIL.NO_O365')}}
                span.d-flex.align-center.justify-center(v-if="invalidO365Token") {{$t('CAMPAIGN_DETAIL.INVALID_O365_TOKEN')}}
                p
                v-row.d-flex.align-center.justify-center(no-gutters='no-gutters')                
                    v-btn(@click='goEmailSync' width='80' height='32' color='green' depressed dark)                
                        span {{$t('CAMPAIGN_DETAIL.GO_SETTING')}}
                    //- label 請先同步O365帳號                                                            
        v-row(no-gutters v-if='objectType == "SMS"')
            v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                p.d-flex.align-center.input-has-label
                    label {{$t('CAMPAIGN_DETAIL.SMS_CONTENT_2')}}
                    v-textarea(v-model='template' solo dense )        
    v-dialog(v-model='emailCampaignDialog' width='720' content-class='statusDialog' v-if='emailCampaignDialog')
        email-campaign-dialog(@emitEmailCampaignDialog='onEmitEmailCampaignDialog' :email-data='emailData' :addSegmentData='addSegmentData' :addCampaignData='addCampaignData')
    v-dialog(v-model='smsCampaignDialog' width='480' content-class='statusDialog' v-if='smsCampaignDialog')
        sms-campaign-dialog(@emitSMSCampaignDialog='onEmitSMSCampaignDialog' :sms-data='smsData' :addSegmentData='addSegmentData' :addCampaignData='addCampaignData')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>

<script>
    import Vue from 'vue';    
    import { VueEditor } from "vue2-editor";
    import O365Service from "../../services/O365Service";
    import errorDialog from '@/components/Dialog/errorDialog';
    import loadingOverlay from "@/components/Common/loadingOverlay";    
    import EmailTemplateDataService from "@/services/EmailTemplateDataService";
    import SMSTemplateDataService from "@/services/SMSTemplateDataService";
    import smsCampaignDialog from '@/components/Dialog/smsCampaignDialog';
    import emailCampaignDialog from '@/components/Dialog/emailCampaignDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        components: {
            VueEditor,
            loadingOverlay,
            errorDialog,
            smsCampaignDialog,
            emailCampaignDialog,
            messageDialog,
        },
        props: {
            objectType: {
                type: String,
                required: true,
            },
            ownerData: {
                type: String,
                required: true,
            },
            contactListDetail: {
                type: Array,
                required: false,
            },
            userEmail: {
                type: String,
                required: false,
            },
            sentEmailList: {
                type: Array,
                required: false,
            },
            fromCampaign: {
                type: Boolean,
                required: false,
            }
        },
        data() {
            return {     
                message:"",
                messageDialog: false,
                showDialogContent: false,
                isO365: false, 
                invalidO365Token: false,
                emailCampaignDialog: false,
                smsCampaignDialog: false,
                addSegmentData:{},                
                template: "",
                templateIndex: "",
                emailTemplateList: [],
                smsTemplateList: [],
                attachments: [],
                emailTemplate: "",
                smsTemplate: "",   
                emailTemplateTabs: '',             
                formRules: {                   
                    // fileRule: [files => !files || !files.some(file => file.size > 2_097_152) || 'Attachment should be less than 2 MB!'],
                    fileRule: [files => !files || this.isFileAvailable || i18n.t('RULE.RULE_FILE')],
                },
                isFileAvailable: true,
                fileAmountSize: 0,
                loading: false,
                errorDialog: false,
                errorMessage: '',                
                o365AccountData: [],
                subject: '',                               
                customToolbar: [
                    [{ 'header': [false, 1, 2, 3, ] }],
                    ["bold", "italic", "underline"],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, ],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ 'color': [] }, { 'background': [] }],
                    ['link'],
                    ['clean']
                ],                
            }
        },
        created(){
            if(this.objectType === "EMAIL")   
            {EmailTemplateDataService.getAll()
            .then(response => {
                this.emailTemplateList = response.data;
            })}
            if(this.objectType === "SMS") 
            {SMSTemplateDataService.getAll()
            .then(response => {
                this.smsTemplateList = response.data;
            })}
            O365Service.getO365Accounts()
            .then(response => {
                this.o365AccountData = response.data;
                if (this.o365AccountData.length > 0) {
                    this.invalidO365Token = (this.o365AccountData[0].refresh_token_message != '') ? true : false;
                    if (!this.invalidO365Token) {
                        this.isO365 = true;
                    }
                    this.showDialogContent = true;
                }
            });            
        },              
        methods: {
            validateForm(){
                if(this.$refs.formEmailFile.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            goEmailSync(){
                this.$router.replace('/Setting#EmailSync')
            },
            getTimeNow(){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = new Date();                
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }               
            },
            async preview() {
                this.addSegmentData = {
                    "name":this.ownerData+'-'+this.objectType+' '+"Segment at "+this.getTimeNow(),
                    "type":"STATIC",
                    "description":null,
                    "contacts":this.sentEmailList,
                }

                this.addCampaignData = {
                    "name":this.ownerData+'-'+this.objectType+' '+"Campaign at "+this.getTimeNow(),
                    "channel":this.objectType,
                    "segment": {},
                    "description": null
                }

				if(this.objectType == "SMS"){
                    if (!this.template) {
                        this.showMessageDialog(i18n.t('CAMPAIGN_DETAIL.ERROR_2'));
                    } else {
                        this.smsData = {
                            "campaign_id": 0,
                            "campaign_name": "",
                            "body": this.template,
                            "contacts": this.contactListDetail
                        }
                        this.smsCampaignDialog = true;
                    }
                }

                if (this.objectType == "EMAIL") {
                    if (this.subject == "") {
                        this.showMessageDialog(i18n.t('CAMPAIGN_DETAIL.ERROR'));
                        return;
                    } else if (this.template == "") {
                        this.showMessageDialog(i18n.t('CAMPAIGN_DETAIL.ERROR_2'));
                        return;
                    }
                    this.emailData = {
                        "campaign_id": 0,
                        "campaign_name": "",
                        "subject": this.subject,
                        "attachments": this.attachments,
                        "body": this.template,
                        "contacts": this.contactListDetail
                    }
                    this.emailCampaignDialog = true;
                }
            },
            onSelectionChange(range) {
                if (range != null) {
                    this.templateIndex = range.index;
                }
            },
            onTextChange(delta) {
                // this.templateIndex = delta.ops[0].retain + 1;
                if (Object.keys(delta.ops[0])[0] == 'retain') {
                    if (Object.keys(delta.ops[1])[0] == 'insert') {
                        if (delta.ops[1].insert.length <= 2) {
                            this.templateIndex = delta.ops[0].retain + 1;
                        }
                    } else if (Object.keys(delta.ops[1])[0] == 'delete') {
                        this.templateIndex = delta.ops[0].retain;
                    }
                }
            },
            addEmailTemplateFields(val) {
                let injectedStr = `{{${val}}}`;
                const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi;
                let html = this.template;
                if (html == "") {
                    this.template = injectedStr;
                    this.templateIndex = injectedStr.length;
                    return true;
                }
                let htmlArr = html.match(pattern);
                let htmlTextSizeArr = htmlArr.map(t => t.replace(/<[^>]+>/g,"").length+1);	// get text length of within each html tag
                let position = this.templateIndex;						// position of cursor.  If 0, add to the end
                if (position == 0) {
                    let text = htmlArr[0];
                    let lastPos = text.indexOf(">") + 1;
                    let newText = [text.slice(0, lastPos), injectedStr, text.slice(lastPos)].join('');
                    htmlArr[0] = newText;
                    this.templateIndex = injectedStr.length;
                } else {
                    let i = 0;
                    while(position >= 0) {
                        position -= htmlTextSizeArr[i++];
                    }
                    let targetInArr = i-1;
                    if (htmlTextSizeArr[targetInArr] == 1) {	// only <br> in the line
                        htmlArr[targetInArr] = htmlArr[targetInArr].replace('<br>', injectedStr);
                    } else {
                        let htmlText = htmlArr[targetInArr];
                        let positionInArr = position + htmlTextSizeArr[targetInArr];
                        let j = 0;
                        let startCounting = true;
                        while(positionInArr > 0) {
                            let currentChar = htmlText.charAt(j);
                            switch(currentChar) {
                                case "<":
                                    startCounting = false;
                                    break;
                                case ">":
                                    startCounting = true;
                                    break;
                                default:
                                    if (startCounting)
                                        positionInArr--;
                                    break;
                            }
                            j++;
                        }
                        let newHtmlText = [htmlText.slice(0, j), injectedStr, htmlText.slice(j)].join('');
                        htmlArr[targetInArr] = newHtmlText;
                    }
                    this.templateIndex = this.templateIndex + injectedStr.length;
                }
                this.template = htmlArr.join('');
            },
            changeTemplate() {
                if (this.objectType == "EMAIL") {
                    let index = this.emailTemplateList.findIndex(item => {return item.id == this.emailTemplate});
                    this.subject = this.emailTemplateList[index].subject;
                    this.template = this.emailTemplateList[index].template;
                } else {
                    let index = this.smsTemplateList.findIndex(item => {return item.id == this.smsTemplate});
                    this.template = this.smsTemplateList[index].template;
                }
            }, 
            onEmitEmailCampaignDialog() {
                // this.defaultData()
                this.emailCampaignDialog = false;
            },
            onEmitSMSCampaignDialog() {
                // this.defaultData()
                this.smsCampaignDialog = false;
            },
            defaultData(){
                this.template = "",
                this.subject =""
                this.attachments = []
                this.emailTemplate = ""
                this.smsTemplate = ""
                this.emailTemplateTabs = ''
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog() {
                this.messageDialog = false;
            },
            onCancel() {
                this.$emit("emitSendEmailDialog", false);            
            },
            onChangeFile(event) {
                let sum = 0;
                event.forEach(file => {
                    sum = sum + file.size;
                });

                if (sum > 20971520) {
                    this.isFileAvailable = false;
                } else {
                    this.isFileAvailable = true;
                }
            },
        },            
    });
</script>
<style lang="css">
/* .template {
    border: 2px solid gray;
    border-radius: 5px;    
    padding: 10px 15px;
} */
</style>