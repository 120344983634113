<template lang="pug">
#EmailEditor
    loadingOverlay(v-show='loading' message='Sending Email . . .' :zIndex='999')
    v-row(v-if='!hasO365' no-gutters)
        span(v-if='!invalidO365Token') {{$t('EMAILEDITOR.NO_O365')}}
        span(v-if='invalidO365Token') {{$t('EMAILEDITOR.INVALID_O365_TOKEN')}}
        a.t-primary.mx-4.ellipsis(:href="'/Setting#EmailSync'" color='black' text) {{$t('EMAILEDITOR.GO_SETTING')}}
        //- span You have not setup O365 account yet.  To send email, please go to Setting > Email Sync to setup O365 account.  Thanks!
    v-row(v-if='hasO365 && !invalidO365Token' no-gutters)    
        v-col(cols='12')
            p.d-flex.align-center.input-has-label
                label {{ $t('EMAILEDITOR.RECIPIENT') }}
                v-combobox.py-1(:menu-props="{value: openMenu_recipient}" v-model='toCcList[0]' :items='people_recipient' item-value='id' item-text='searchStr' solo dense height='40' multiple chips :search-input.sync="search_recipent" @blur='openMenu_recipient = false' :loading='loading_recipient' :no-data-text=`$t('EMAILEDITOR.NO_DATA')` style='z-index:99;')
                    template(v-slot:selection='data')
                        v-chip(v-bind="data.attrs"
                            :input-value="data.selected"
                            outlined
                            close=true
                            @click="data.select"
                            @click:close="removePeople(0, data.index)")
                            v-avatar(v-if='data.item.id != undefined' left color='aliceBlue')
                                span.mx-auto(v-if='data.item.image === null') {{ getFirstLetters(data.item.last_name + ' ' + data.item.first_name) }}
                                v-img(v-else :src='data.item.image')
                            span(v-if='data.item.id != undefined') {{ getName(data) }}
                            span(v-else) {{ data.item }}
                    template(v-slot:item='data')
                        v-list-item-avatar.mr-2(color='aliceBlue')
                            span.mx-auto(v-if='data.item.image === null') {{ data.item.last_name }}
                            img(v-else :src='data.item.image' :alt='data.item.last_name')
                        v-list-item-content
                            v-list-item-title(v-html='getName(data)')
        v-col(cols='12')
            p.d-flex.align-center.input-has-label
                label {{ $t('EMAILEDITOR.COPY') }}
                v-combobox.py-1(:menu-props="{value: openMenu_cc}" v-model='toCcList[1]' :items='people_cc' item-value='id' item-text='searchStr' solo dense height='40' multiple chips :search-input.sync="search_cc" @blur='openMenu_cc = false' :loading='loading_cc' :no-data-text=`$t('EMAILEDITOR.NO_DATA')` style='z-index:99;')
                    template(v-slot:selection='data')
                        v-chip(v-bind="data.attrs"
                            :input-value="data.selected"
                            outlined
                            close=true
                            @click="data.select"
                            @click:close="removePeople(1, data.index)")
                            v-avatar(v-if='data.item.id != undefined' left color='aliceBlue')
                                span.mx-auto(v-if='data.item.image === null') {{ getFirstLetters(data.item.last_name + ' ' + data.item.first_name) }}
                                v-img(v-else :src='data.item.image')
                            span(v-if='data.item.id != undefined') {{ getName(data) }}
                            span(v-else) {{ data.item }}
                    template(v-slot:item='data')
                        v-list-item-avatar.mr-2(color='aliceBlue')
                            span.mx-auto(v-if='data.item.image === null') {{ data.item.last_name }}
                            img(v-else :src='data.item.image' :alt='data.item.last_name')                            
                        v-list-item-content
                            v-list-item-title(v-html='getName(data)')
        v-col(cols='12')
            p.d-flex.align-center
                v-file-input.pt0(
                    ref='formEmailFile',
                    :label=`$t('EMAILEDITOR.ATTACHMENT')`,
                    placeholder='', 
                    :rules='fileRule',
                    v-model='attachments',
                    multiple,
                    show-size, 
                    small-chips, 
                    counter,
                    truncate-length="15",
                    @change='onChangeFile'
                )
        v-col(cols='12')
            p.d-flex.align-center.input-has-label
                label {{$t('EMAILEDITOR.SUBJECT')}}
                v-text-field(v-model='subject' solo height='36' dense)
        v-col.mt-4.mb-2(cols='12')
            vue-editor(v-model='mainEditor' :editor-toolbar='customToolbar' :editorOptions='editorSettings' :customModules="customModulesForEditor")
        v-col(cols='12')
            v-row.align-center(no-gutters)
                v-col(cols='10')
                    v-btn(@click='sendEmail()' width='120' height='40' color='primary' depressed dark)
                        span {{$t('EMAILEDITOR.SEND')}}
                    v-btn.ml-2(@click='initialize()' width='120' height='40' color='grey lighten-1' depressed dark)
                        span {{$t('EMAILEDITOR.CLEAR')}}
                v-col(cols='2')
                    v-select(:label=`$t('EMAILEDITOR.TEMPLATE')` v-model='choseEmailTemplate' :items='emailTemplateData' item-text='template_name' item-value='id' height='40' clearable solo flat filled @change='selectTemplate')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    // import _ from 'lodash';
    import { VueEditor } from "vue2-editor";
    import O365Service from "../../services/O365Service";
    import EmailTemplateDataService from "../../services/EmailTemplateDataService";
    import ContactDataService from '@/services/ContactDataService';
    import errorDialog from '@/components/Dialog/errorDialog';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import ImageResize from "quill-image-resize-vue";
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        components: {
            VueEditor,
            loadingOverlay,
            errorDialog
        },
        props: {
            objectType: {
                type: String,
                required: true,
            },
            objectDetail: {
                type: Object,
                required: true,
            },
            contactsDetail: {
                type: Object,
                require: true,
            },
            userEmail: {
                type: String
            }
        },
        data() {
            return {
                fileRule: [files => !files || this.isFileAvailable || i18n.t('EMAILEDITOR.ERROR')],
                isFileAvailable: true,
                fileAmountSize: 0,
                emailId: '',
                attachments: [],
                addressee: 0,
                copy: 1,
                toCcList: [[], []],
                loading: false,
                errorDialog: false,
                hasO365: false,
                invalidO365Token: false,
                emptyList: [],
                choseEmailTemplate: '',
                errorMessage: '',
                emailTemplateData: [],
                o365AccountData: [],
                subject: '',
                mainEditor: '',
                customModulesForEditor: [
                    { alias: "imageResize", module: ImageResize }
                ],                
                customToolbar: [
                    [{ 'header': [false, 1, 2, 3, ] }],
                    ["bold", "italic", "underline"],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, ],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ 'color': [] }, { 'background': [] }],
                    ['link'],
                    ['clean']
                ],
                editorSettings: {
                    modules: {
                        imageResize: {}
                    }
                },
                people_recipient: [],
                people_cc: [],
                search_recipent: null,
                search_cc: null,
                loading_recipient: false,
                loading_cc: false,
                openMenu_recipient: false,
                openMenu_cc: false,
            }
        },
        created(){              
            O365Service.getO365Accounts()
            .then(response => {
                this.o365AccountData = (response.data);
                if (this.o365AccountData.length > 0) {
                    this.hasO365 = true;
                    this.invalidO365Token = (this.o365AccountData[0].refresh_token_message != '') ? true : false;
                    this.addContactToAddress();
                    EmailTemplateDataService.getAll()
                    .then(response => {
                        this.emailTemplateData = (response.data);
                    })
                }
            });
        },        
        methods: {
            validateForm(){
                if(this.subject != '' && this.mainEditor != '' && this.toCcList[this.addressee].length > 0 && this.$refs.formEmailFile.validate())
                    return true;
                else
                    return false;
            },
            initialize() {
                this.toCcList[this.addressee] = [];
                this.toCcList[this.copy] = [];
                this.subject = '';
                this.attachments = [];
                this.clearContent();
                this.addContactToAddress();
            },
            async addContactToAddress() {
                let hasEmail = Boolean(this.contactsDetail.email);
                if(hasEmail){
                    var image = '';
                    await ContactDataService.getContactsDetail(this.contactsDetail.id)
                    .then(response => {
                        image = response.data.image;
                    });

                    let contactEmailData = {
                        first_name: this.contactsDetail.first_name,
                        id: this.contactsDetail.id,
                        image: image,
                        last_name: this.contactsDetail.last_name,
                        email: this.contactsDetail.email,
                        searchStr: this.contactsDetail.full_name
                                    +this.contactsDetail.full_name_en
                                    +this.contactsDetail.full_name_zh
                                    +this.contactsDetail.email
                    }
                    await this.toCcList[this.addressee].push(contactEmailData);
                 }
            },
            selectTemplate(v) {
                if (v) {
                    let template_data = this.emailTemplateData.find(item => item.id == this.choseEmailTemplate)
                    EmailTemplateDataService.transformEmail(this.objectType, this.objectDetail.id, template_data.template)
                    .then(response => {
                        this.subject = template_data.subject;
                        this.mainEditor = response.data;
                    });
                } else {
                    this.clearContent();
                }
            },
            clearContent() {
                this.mainEditor = '';
                this.choseEmailTemplate = '';
                // this.emailTemplateContactValList = '';
                // this.emailTemplateDealValList = '';                
            },
            async sendEmail() {
                if (this.validateForm()) {
                    this.loading = true;
                    let toAddressee = [];
                    let toCopy = [];
                    let toAddresseeStr = [];
                    let toCopyStr = [];
                    this.toCcList[this.addressee].forEach(email_data => {
                        if (email_data.id != undefined) {
                            toAddressee.push(email_data.id);
                        } else {
                            toAddresseeStr.push(email_data);
                        }
                    });
                    this.toCcList[this.copy].forEach(email_data => {
                        if (email_data.id != undefined) {
                            toCopy.push(email_data.id);
                        } else {
                            toCopyStr.push(email_data);
                        }
                    });

                    let error_email = [];
                    let permission = true;
                    
                    var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
                    toAddresseeStr.forEach(email => {
                        if (pattern.test(email)) {
                            toAddressee.push(email);
                        } else {
                            error_email.push(email);
                            permission = false;
                        }
                    });
                    toCopyStr.forEach(email => {
                        if (pattern.test(email)) {
                            toCopy.push(email);
                        } else {
                            error_email.push(email);
                            permission = false;
                        }
                    });

                    if (permission == true) {
                        let formData = new FormData();
                        formData.append('sender', this.userEmail);
                        formData.append('recipient', JSON.stringify({ "to": toAddressee, "cc": toCopy }));
                        formData.append('subject', this.subject);
                        formData.append('body', this.mainEditor);
                        formData.append('direction', 1);             // 1 - Send
                        formData.append('message_id', 'SOMEID');
                        if (this.objectType == 'Opportunity')        // multipart/form-data convention for many relationship
                        {
                            formData.append('opptys[0]id', this.objectDetail.id);
                            formData.append('opptys[0]name', this.objectDetail.name);
                        }
                        if (this.objectType == 'Case')        // multipart/form-data convention for many relationship
                        {
                            formData.append('cases[0]id', this.objectDetail.id);
                            formData.append('cases[0]subject', this.objectDetail.subject);
                        }
                        for (let i=0; i<this.attachments.length; i++) {
                            formData.append(`emailattachment_set[]`, this.attachments[i]);
                        }

                        await O365Service.sendEmail(formData)
                        .then(response => {
                            this.initialize();
                            this.emailId = response.data.id;
                            this.loading = false;
                            this.$emit('emitSendEmailDialog', true);
                        }).catch(response => {
                            this.loading = false;
                            this.showErrorDialog(response.data);
                        });
                    } else {
                        this.showErrorDialog(error_email + i18n.t('EMAILEDITOR.ERROR_2'));
                        this.loading = false;
                    }
                } else {
                    // this.showErrorDialog('Please enter all field before send email');
                    this.showErrorDialog(i18n.t('EMAILEDITOR.ERROR_3'));
                }
            },
            getFullName(lastName, firstName) {
                return lastName + ' ' + firstName;
            },
            getName(data) {
                if (data.item.first_name != undefined || data.item.first_name != null)
                    return data.item.last_name + ' ' + data.item.first_name;
                else 
                    return data.item.last_name;
            },
            getFirstLetters(str) {
                const firstLetters = str.split(' ').map(word => word[0]).join('');
                return firstLetters;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            removePeople(model, index) {
                this.toCcList[model].splice(index, 1);
            },
            onChangeFile(event) {
                let sum = 0;
                event.forEach(file => {
                    sum = sum + file.size;
                });

                if (sum > 20971520) {
                    this.isFileAvailable = false;
                } else {
                    this.isFileAvailable = true;
                }
            },
        },
        watch: {
            async search_recipent(val){

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;

                if(isEnZhNum && isString && !!val){
                    
                    this.loading_recipient = true;
                    this.people_recipient = [];
                    let array = [];
                    // search
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            let hasEmail = Boolean(item.email);
                            let filter = false;
                            if(hasEmail){
                                filter = item.full_name.includes(val) 
                                        || item.full_name_en.includes(val)
                                        || (item.full_name_en.toLowerCase()).includes(val)
                                        || (item.full_name_en.toUpperCase()).includes(val)
                                        || item.full_name_zh.includes(val)
                                        || item.email.includes(val);
                                
                                if(filter){
                                    let peopleData = {
                                        first_name: item.first_name,
                                        id: item.id,
                                        image: item.image,
                                        last_name: item.last_name,
                                        email: item.email,
                                        searchStr:  item.full_name 
                                                    +item.full_name_en
                                                    +item.full_name_en.toLowerCase()
                                                    +item.full_name_en.toUpperCase()
                                                    +item.full_name_zh
                                                    +item.email
                                    }
                                    array.push(peopleData)
                                }
                            }
                        })
                        this.people_recipient = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.loading_recipient = false;
                        this.openMenu_recipient = true;
                    })
                    
                }
            },
            async search_cc(val){

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;

                if(isEnZhNum && isString && !!val){
                    
                    this.loading_cc = true;
                    this.people_cc = [];
                    let array = [];
                    // search
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            let hasEmail = Boolean(item.email);
                            let filter = false;
                            if(hasEmail){
                                filter = item.full_name.includes(val) 
                                        || item.full_name_en.includes(val)
                                        || item.full_name_zh.includes(val)
                                        || item.email.includes(val);
                                
                                if(filter){
                                    let peopleData = {
                                        first_name: item.first_name,
                                        id: item.id,
                                        image: item.image,
                                        last_name: item.last_name,
                                        email: item.email,
                                        searchStr:  item.full_name 
                                                    +item.full_name_en
                                                    +item.full_name_zh
                                                    +item.email
                                    }
                                    array.push(peopleData)
                                }
                            }
                        })
                        this.people_cc = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.loading_cc = false;
                        this.openMenu_cc = true;
                    })
                    
                }
            }
        }
    });
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(190, 190, 190, 0.5);
}

</style>